


























































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'

@Component({
    name: 'Success',
    components: {
        VsContainer,
        VsFullTopBarLayout,
    },
})

export default class extends Vue {
}
